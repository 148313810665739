<template>
 
  <v-app style="background-color:#f8f9fb" >

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 5 anos.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

   
    <!-- Diálogo de progresso -->
    <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-container >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes"  :modos="modos" :tipos="tipos" :calendar="'mes'" :produtos="tipos_funerarios" 
      @filtrar="filterData" :title_opcao="'Tipo'"   
        ></filtro-component>
    </v-col>
  </v-container>


  <v-container style="background-color:#eee"  
    id="contrato"
    fluid
    tag="section">
 
    <v-row>
    
    <v-col
    cols="12">

        <base-material-card
        icon="mdi-cash-refund"
        title="Listagem das Remessas - SERASA"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="remessa_header"
              :items="remessa"
              :items-per-page="50" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      

                        <template v-slot:item="row">
                          
                          <tr :class="{'gray-row': row.index % 2 !== 0}">

                            <td style="width: 15%;font-size:14px;">
                               {{row.item.idlote}}
                            
                            </td>
                            <td style="width: 25%;font-size:14px;">{{formatarData(row.item.dtEnvio)}} </td>

                            <td style="width:10%;font-size:14px;">
                              <v-chip 
                              :color="getEmpresa(row.item.empresa)"
                              text-color="white">
                                  {{row.item.empresa}}                    
                                  
                              </v-chip>  
                            </td>
                            <td style="width: 15%;font-size:14px;">{{row.item.referencia}} </td>   

                
                            <td style="width: 6%;font-size:14px;">

                              <v-chip outlined :color="verificarCor(row.item.operacao)" >
                                {{row.item.operacao}}                               
                              </v-chip>

                            </td>

                            <td>
                              <v-btn color="primary" @click="download_txt_lote(row.item.idlote, row.item.nomeArquivo, row.item.operacao)" fab small>

                                  <v-icon>
                                        fa fa-download
                                      </v-icon>
                                  </v-btn>

                              </td>


                            
                          </tr>
                      </template>
              </v-data-table>


    <br/>

  

          </base-material-card>
  
          <br/>

          <base-material-card
        icon="mdi-file-upload"
        title="Processamento do Retorno - Serasa"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 
          <template>
            <div>
              <br/>

              <label for="file-upload" class="file-file-upload">
                <input type="file" id="file-upload" @change="handleFileUpload" />
              </label>

              <br/>
            </div>
          </template>
          </base-material-card>
  

    </v-col>

   
  

  </v-row>



  </v-container>

</v-app> 
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Cobranca from '../../services/cobranca'
import { ContentLoader } from 'vue-content-loader'
import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

  export default {
    name: 'Cobranca',
  components: {
    apexcharts: VueApexCharts,
    FiltroComponent,
    ContentLoader,

  },
  
  created() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      this.selectedStartDate = `${"01"}/${month}/${year}`;     // `${"01"}/${month}/${year}`;
      this.selectedEndDate =  this.formatDateBR(new Date())
     
    },
    
  mounted() {

    this.dashboard();
   
    
  },

 
    data () {
      return {
       
        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          empresa: 'Todas',
          search: "",
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",

          selectMode: "Todos",
          produto: 'Todos',
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: ["Todas", "Inclusão",  "Exclusão"],
          modos: ["Geração"],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos"],

          bool_01:true,
          bool_02:true,
          bool_03:true,
          bool_04:true,
          showProgress: false,
          currentGroup: null,
          shownDataAcordo: new Set(),


          tipos_funerarios: [
                "Todos",
              ],
        


        remessa_header: [
          { text: 'Id Lote', value: 'idlote' },
          { text: 'Data', value: 'dtEnvio' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Referência', value: 'referencia' },
          { text: 'Operação', value: 'operacao' },
          { text: 'Download', value: '' },


        ],

        remessa: [],
       

      }
  },

    methods: {


      
          verificarCor(valor) {

            if (valor == 'Exclusão') {
              return 'orange';
            } 

            return 'green'
            },

     
        formatarData(data) {
          if (!data) return ''; // Verificar se a data não está vazia ou indefinida

          // Dividir a data em ano, mês e dia
          const partes = data.split('-');
          if (partes.length !== 3) return data; // Se a data não estiver no formato esperado, retornar a data original

          // Montar a data no formato desejado (dd/mm/aaaa)
          const dia = partes[2];
          const mes = partes[1];
          const ano = partes[0];
          return `${dia}/${mes}/${ano}`;
        },

      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },
            formatarObs(obs) {
              // Substituir \n por <br> para exibir corretamente no HTML
              return obs.replace(/\n/g, '<br>');
            },

       getFirstDayOf60YearsAgo() {
            const dataAtual = new Date();
            const anoAtual = dataAtual.getFullYear();
            const ano60Atras = anoAtual - 60;
            const primeiroDiaDoAno60Atras = new Date(ano60Atras, 0, 1); // Janeiro é representado como 0
            return this.formatDateBR(primeiroDiaDoAno60Atras);
        },

       filterData(valores) {

         this.isLoading = true;
         let nova_consulta = false;

          if (this.selectedStartDate !== valores.selectedStartDate || this.selectedEndDate !== valores.selectedEndDate
          || this.selectedCompany !== valores.selectedCompany
          || this.selectedType !== valores.selectedType) {
            nova_consulta = true;
          }

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.produto = "Todos";
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;

        if(nova_consulta){
          this.total_cobranca_reneg_volume = 0,
          this.total_cobranca_reneg_cancelado = 0,
          this.dashboard();

        }else{
            //console.log('Valor'," Recerregar Dados");
        }

      },


      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

     

    getEmpresa (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },

      download_txt_lote(lote, nome, tipo) {
              
              this.showProgress = true;
               
              Cobranca.lote_txt(lote, tipo)
              .then(response => {
                       if (response.status === 200) {
 
                             const fileName = nome;
                             const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                             const fileLink = document.createElement('a')
                             fileLink.href = fileURL
                             fileLink.setAttribute('download', fileName)
                             document.body.appendChild(fileLink)
                             fileLink.click()
 
                         }
                      })
                      .catch(error => {
                        // Lida com erros
                        console.error('Erro ao baixar o arquivo txt:', error);
                      }) .finally(() => {
                        // Após o término do download, oculte o diálogo de progresso
                        this.showProgress = false;
                      });
 
 
                      
     },

   

      dashboard() {
 
              //const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
             // const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              Cobranca.lista_remessa(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, this.produto, this.selectedOpcao).then(response => {
                          if(response.status === 200){
                            this.remessa = response.data;
                          } 

                        }).catch(e => {    
                        }).finally(() => {
                         
               });

      },

      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          if (!file.name.includes('PEFIN.RET')) {
            alert('ARQUIVO NÃO É VÁLIDO');
            return; // Não processa o arquivo
          }
          
          this.readFile(file);
        }
      },
      readFile(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Extraia o valor da posição 120 a 125 da primeira linha
          const headerValue = json[1] ? json[0][0].toString().slice(119, 125) : '';
          const headerValue_cnpj = json[1] ? json[0][0].toString().slice(1,10) : '';

          // Processa os dados diretamente
          const filteredData = json
            .slice(1) // Ignora o cabeçalho
            .filter(row => row[0] && row[0].toString().startsWith('1'))
            .map(row => {
              // Extraia os valores das posições 106 a 175 para a primeira coluna
              const value = row[0].toString().slice(105, 175); // Ajuste se necessário
              // Extraia os valores das posições 534 a 593 e obtenha a descrição
              const additionalValue = row[0].toString().slice(533, 593); // Ajuste se necessário
              const description = additionalValue.trim() === '' 
                ? 'Entrada Confirmada'
                : this.getDescription(additionalValue.slice(0, 3)); // Ajuste se necessário
              
              return [value, description];
            });

          this.generatePDF(filteredData, headerValue, headerValue_cnpj, file.name);
        };
        reader.readAsArrayBuffer(file);
      },
      getDescription(code) {
  // Função para obter a descrição com base no código
          const descriptions = {
          '000': 'REGISTRO AR - ASSINADO',
          '002': 'PRIMEIRO REGISTRO NAO E HEADER/SEQUENCIA DIFERENTE DE 1',
          '003': 'REGISTRO DIFERENTE DE 0, 1 E 9',
          '004': 'REGISTRO FORA DE SEQUENCIA',
          '005': 'REGISTRO TRAILLER NAO INFORMADO',
          '008': 'CODIGO DE OPERACAO DIFERENTE DE E, I',
          '009': 'TIPO DE PESSOA PRINCIPAL DIFERENTE DE F, J',
          '010': 'TIPO DE PESSOA COOBRIGADO DIFERENTE DE F, J',
          '011': 'NOME INVALIDO',
          '012': 'NOME NAO PODE SER BRANCOS',
          '013': 'DATA DO HEADER INVALIDA',
          '014': 'DATA DA OCORRENCIA INVALIDA',
          '016': 'DATA DA OCORRENCIA MAIOR QUE A ATUAL',
          '017': 'DATA DA OCORRENCIA JA DECURSADA',
          '018': 'IDENTIFICACAO DO ARQUIVO INVALIDA',
          '019': 'REMESSA NAO POSTERIOR A ULTIMA',
          '020': 'REMESSA NAO NUMERICA OU IGUAL A ZERO',
          '021': 'CAMPO DE RECEBIMENTO DA REMESSA DIFERENTE DE \'E\'',
          '022': 'EXCLUSAO PARA REGISTRO INEXISTENTE',
          '024': 'CNPJ DO HEADER INVALIDO',
          '025': 'CNPJ NAO CADASTRADO NO SISTEMA (TABELA DE REMESSA)',
          '028': 'DOCUMENTO DO PRINCIPAL NAO NUMERICO',
          '029': 'DOCUMENTO DO PRINCIPAL INVALIDO',
          '030': 'DOCUMENTO DO COOBRIGADO NAO NUMERICO',
          '031': 'DOCUMENTO DO COOBRIGADO INVALIDO',
          '033': 'TRAILLER FORA DE SEQUENCIA',
          '034': 'SEQUENCIA NAO NUMERICA',
          '035': 'NATUREZA INVALIDA',
          '036': 'NATUREZA INVALIDA PARA O CNPJ INFORMADO',
          '040': 'REG. NAO ATUALIZADO. ERRO NO PRINCIPAL OU COOBRIGADO DA OCORRENCIA',
          '044': 'AGENCIA INVALIDA',
          '047': 'CONTA NAO NUMERICA',
          '051': 'TIPO DE PARTICIPANTE DO DENUNCIADO INVALIDO',
          '052': 'MEIO DE COMUNICACAO INVALIDO',
          '053': 'TIPO DE MOVIMENTO INVALIDO',
          '057': 'VALOR NAO NUMERICO',
          '059': 'INCLUSAO PARA REGISTRO JA EXISTENTE',
          '064': 'CONTA INVALIDA',
          '065': 'CHEQUE INVALIDO',
          '072': 'ALINEA INVALIDA',
          '081': 'REGISTRO APOS TRAILLER',
          '085': 'NUMERO DO CONTRATO/TITULO INVALIDO',
          '089': 'EMPRESA NAO PARTICIPANTE DO CONVENIO',
          '102': 'NUMERO DO CONTRATO/TITULO EH OBRIGATORIO',
          '103': 'TIPO DE DOCUMENTO INVALIDO',
          '105': 'INCLUSAO BLOQUEADA FACE A DETERMINACAO ADMINISTRATIVA',
          '106': 'MOVIMENTO APOS RESCISAO DE CONTRATO DO CONVENIO',
          '109': 'FALTA ENDERECO',
          '111': 'FALTA UF DO ENDERECO',
          '112': 'FALTA CEP DO ENDERECO',
          '113': 'FALTA INFORMACAO TIPO DE PESSOA',
          '115': 'TIPO DE PESSOA DO PRINCIPAL DIFERENTE DE F',
          '116': 'TIPO DE PESSOA DO COOBRIGADO DIFERENTE DE F',
          '119': 'CEP DO ENDERECO INVALIDO',
          '121': 'DATA DE NASCIMENTO INFERIOR A 18 ANOS',
          '142': 'BANCO NAO TRABALHA COM CONTA CORRENTE',
          '156': 'DATA OCORRENCIA INFERIOR A 1 DIA',
          '166': 'DATA DE TERMINO DO CONTRATO INVALIDA',
          '169': 'AREA INFORMANTE DA REMESSA C/ ERRO. MOVIMENTO REJEITADO',
          '170': 'BANCO INVALIDO P/ NATUREZA DE/DC',
          '171': 'TIPO DE PESSOA DO CREDOR DIFERENTE DE F, J',
          '172': 'TIPO DE DOCUMENTO DO CREDOR INVALIDO',
          '173': 'DOCUMENTO DO CREDOR NAO NUMERICO',
          '174': 'DOCUMENTO DO CREDOR INVALIDO',
          '175': 'NOME DO CREDOR EH OBRIGATORIO',
          '176': 'NOME DO CREDOR INVALIDO',
          '200': 'BANCO DO HEADER INVALIDO',
          '205': 'BANCO NAO CADASTRADO NO SISTEMA (TABELA DE BANCOS)',
          '209': 'DOCUMENTO DO DEVEDOR NAO NUMERICO',
          '210': 'DOCUMENTO DO DEVEDOR INVALIDO',
          '219': 'VALOR DO TITULO INVALIDO',
          '263': 'IDENTIFICACAO DO ARQUIVO INVALIDA',
          '274': 'CNPJ NAO EXISTE NO CADASTRO DE CNPJ/CPF ATE ESTA DATA',
          '275': 'CPF NAO EXISTE NO CADASTRO DE CNPJ/CPF ATE ESTA DATA',
          '276': 'FILIAL NAO EXISTE NO CADASTRO DE CNPJ/CPF ATE ESTA DATA',
          '277': 'CNPJ CREDOR/CEDENTE NAO EXISTE NO CADASTRO DE CNPJ/CPF ATE ESTA DATA',
          '278': 'CPF CREDOR/CEDENTE NAO EXISTE NO CADASTRO DE CNPJ/CPF ATE ESTA DATA',
          '279': 'FILIAL CREDOR/CEDENTE NAO EXISTE CADASTRO DE CNPJ/CPF ATE ESTA DATA',
          '286': 'DOCUMENTO DO CREDOR IGUAL AO DO NEGATIVADO',
          '290': 'EXCLUSAO POR DATA DE OCORRENCIA JA DECURSADA',
          '291': 'EXCLUSAO POR DETERMINACAO JUDICIAL',
          '292': 'EXCLUSAO POR SOLICITACAO DA EMPRESA PARTICIPANTE',
          '295': 'RAZAO SOCIAL NAO CORRESPONDE AO CNPJ INFORMADO',
          '296': 'NOME NAO CORRESPONDE AO CPF INFORMADO',
          '298': 'COOBRIGADO NAO INCLUIDO - PRINCIPAL NAO ENCONTRADO',
          '301': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - MUDOU-SE',
          '302': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - END INSUFICIENTE',
          '303': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - NR INEXISTENTE',
          '304': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - DESCONHECIDO',
          '305': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - RECUSADO',
          '306': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - NAO PROCURADO',
          '307': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - AUSENTE',
          '308': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - FALECIDO',
          '309': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO CORREIO - INFORM P/ PORTEIRO',
          '310': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - END N CONHECIDO',
          '311': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - CEP INCORRETO',
          '312': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO DO CORREIO - N ESPECIFICADO',
          '313': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO CORREIO - CX POSTAL INEXIST',
          '314': 'REGISTRO ESPECIAL - DEVOLUCAO COMUNICADO CORREIO - IMOVEL INEXISTENTE',
          '315': 'DEVIDO A DEVOLUCAO DO COMUNICADO DO CORREIO',
          '316': 'REGISTRO AR - EXTRAVIADO',
          '317': 'REGISTRO AR - ROUBADO',
          '318': 'REGISTRO AR - AUSENTE - ENCAMINHADO PARA ENTREGA INTERNA',
          '319': 'REGISTRO AR - REFUGADO',
          '320': 'REGISTRO AR - ENDERECO INCORRETO',
          '321': 'REGISTRO AR - NAO PROCURADO – DEVOLVIDO AO REMETENTE',
          '322': 'REGISTRO AR - DOCUMENTACAO NAO FORNECIDA',
          '323': 'REGISTRO AR - MUDOU-SE',
          '324': 'REGISTRO AR - DESCONHECIDO',
          '325': 'REGISTRO AR - RECUSADO',
          '326': 'REGISTRO AR - ENDERECO INSUFICIENTE',
          '327': 'REGISTRO AR - NAO EXISTE O NUMERO INDICADO',
          '328': 'REGISTRO AR - AUSENTE',
          '329': 'REGISTRO AR - NAO PROCURADO',
          '330': 'REGISTRO AR - FALECIDO',
          '331': 'REGISTRO AR - DEVIDO A DEVOLUCAO DO COMUNICADO DO CORREIO',
          '356': 'DATA DO COMPROMISSO MAIOR QUE A ATUAL',
          '357': 'DATA DO COMPROMISSO INVALIDA',
          '358': 'VALOR DO COMPROMISSO NAO NUMERICO',
          '359': 'DDD INVALIDO',
          '360': 'TELEFONE INVALIDO',
          '381': 'INCLUSAO REJEITADA - VLR CONSID.ELEVADO - INCLUIR PELO SISCONVEM',
          '390': 'INCLUSAO RECUSADA - PARTICIPANTE COM LOGON BLOQUEADO',
          '391': 'REGISTRO REJEITADO - INCONSISTENCIA NO HEADER',
          '392': 'FALTA CODIGO CLIENTE NA RSREMESSA OU CODIGO NAO TEM 5 CARACTERE',
          '394': 'CNPJ NAO CORRESPONDE AO CODIGO DE CLIENTE INFORMADO',
          '396': 'INCLUSAO REJEITADA-VLR.CONS.ELEVADO-NEGAT.PRIMARIA-INCL.SISCONVEM',
          '488': 'PARTICIPANTE NAO POSSUI PROCURACAO DO CREDOR',
          '489': 'UF DO RG INVALIDO',
          '490': 'FALTAM DADOS CADASTRAIS DO CREDOR DA DIVIDA',
          '491': 'MUNICIPIO NAO CORRESPONDE AO CEP E/OU UF INFORMADO',
          '572': 'EXISTEM DADOS COMPLEMENTARES MAS O INDICATIVO NÃO FOI INFORMADO',
          '573': 'INDICATIVO DE DADOS COMPLEMENTARES DIFERENTE DE BRANCO',
          '574': 'INDICATIVO DE DADOS COMPLEMENTARES ENVIADO, MAS SEM OS DADOS',
          '575': 'DATA DE OPT-IN DO E-MAIL INVALIDA',
          '576': 'DATA DE OPT-IN DO SMS INVALIDA',
          '600': 'MENSAGEM NAO INFORMADA PELO GERENCIADOR',
          '610': 'INCLUSAO CONDICIONADA A APRESENTACAO DE DOCTO DA DIVIDA',
          '650': 'CODIGO DO BANCO INVALIDO',
          '651': 'BANCO NAO CADASTRADO',
          '652': 'DIGITO DO CODIGO DO BANCO INVALIDO',
          '653': 'NOME DO BANCO NAO INFORMADO',
          '654': 'CARACTERES DA LINHA DIGITAVEL INVALIDA',
          '655': 'PRIMEIRO DIGITO DA LINHA DIGITAVEL DIVERGENTE',
          '656': 'SEGUNDO DIGITO DA LINHA DIGITAVEL DIVERGENTE',
          '657': 'TERCEIRO DIGITO DA LINHA DIGITAVEL DIVERGENTE',
          '658': 'QUARTO DIGITO DA LINHA DIGITAVEL DIVERGENTE',
          '659': 'TEXTO DO LOCAL DE PAGAMENTO NAO INFORMADO',
          '660': 'DATA DE VENCIMENTO DO BOLETO INVALIDA',
          '661': 'DATA DE VENCIMENTO DO BOLETO MENOR QUE A DATA DE HOJE',
          '662': 'TIPO DE PESSOA DO DOCUMENTO DO CEDENTE INVALIDO',
          '663': 'TIPO DE DOCUMENTO DO CEDENTE DIVERGENTE DO TIPO DE PESSOA',
          '664': 'DOCUMENTO DO CEDENTE INVALIDO',
          '665': 'DIGITO DO DOCUMENTO DO CEDENTE INVALIDO',
          '666': 'AGENCIA E CODIGO DO CEDENTE NAO INFORMADO',
          '667': 'DATA DO DOCUMENTO INVALIDA',
          '668': 'NUMERO DO DOCUMENTO NAO INFORMADO',
          '669': 'DATA DO PROCESSAMENTO INFORMADA E INVALIDA',
          '670': 'NOSSO NUMERO NAO INFORMADO',
          '671': 'QUANTIDADE DE MOEDA NAO INFORMADA',
          '672': 'QUANTIDADE DE MOEDA INVALIDA',
          '673': 'QUANTIDADE DE DECIMAIS DA MOEDA NAO INFORMADA',
          '674': 'QUANTIDADE DE DECIMAIS DA MOEDA INVALIDA',
          '675': 'QUANTIDADE DE DECIMAIS DA MOEDA MAIOR QUE 5 CASAS DECIMAIS',
          '676': 'VALOR DA MOEDA NAO INFORMADO',
          '677': 'VALOR DA MOEDA INVALIDA',
          '678': 'VALOR DO DOCUMENTO INVALIDO',
          '679': 'VALOR DE OUTROS ACRESCIMOS INVALIDO',
          '680': 'VALOR DE DESCONTOS/ABATIMENTO INVALIDO',
          '681': 'VALOR DE OUTRAS DEDUCOES INVALIDO',
          '682': 'VALOR DE MORA/MULTA INVALIDO',
          '683': 'VALOR COBRADO INVALIDO',
          '684': 'TIPO DE PESSOA DO DOCUMENTO DO SACADOR INVALIDO',
          '685': 'TIPO DE DOCUMENTO DO SACADOR DIVERGENTE DO TIPO DE PESSOA',
          '686': 'DOCUMENTO DO SACADOR INVALIDO',
          '687': 'DIGITO DO DOCUMENTO DO SACADOR INVALIDO',
          '701': 'EXISTE DADOS BOLETO E NAO INFORMADO INDICATIVO DE TIPO DE COMUNICADO',
          '702': 'INDICATIVO DE TIPO DE COMUNICADO DIFERENTE DE BRANCO',
          '703': 'TIPO DE COMUNICADO COMO BOLETO E NAO FOI INFORMADO O REG.TP 2 E/OU 3',
          '704': 'TIPO DE COMUNICADO BOLETO NAO PERMITIDO PARA COOBRIGADO',
          '705': 'REG BOLETO TIPO 2 ENCONTRADO, SEM REGISTRO TIPO 1 CORRESPONDENTE',
          '706': 'REG BOLETO TIPO 3 ENCONTRADO, SEM REGISTRO TIPO 1 CORRESPONDENTE',
          '707': 'PARTICIPANTE NAO POSSUI CONTRATO PARA EMISSAO DE COMUNICADO COM BOLETO',
          '721': 'ENDERECO DO CEDENTE NAO CADASTRADO',
          '739': 'PARTICIPANTE NAO POSSUI CONTRATO PARA EMISSAO DE SMS.',
          '740': 'TELEFONE CELULAR INEXISTENTE',
          '745': 'INCLUSOES REJEITADAS - ESCRITORIO DE COBRANCA',
          '772': 'INCLUSAO REJEITADA EM CUMPRIMENTO A LEI DISTRITAL 3.335/2004-DF',
          '773': 'INCLUSAO REJEITADA EM CUMPRIMENTO A LEI ESTADUAL 7.160/2002-ES',
          '774': 'INCLUSAO REJEITADA EM CUMPRIMENTO A LEI ESTADUAL 3.749/2009-MS',
          '775': 'INCLUSAO REJEITADA EM CUMPRIMENTO A LEI ESTADUAL 4.054/2011-MS',
          '776': 'INCLUSAO REJEITADA POR BLOQUEIO ADMINISTRATIVO DO CLIENTE',
          '779': 'INCLUSAO REJEITADA EM CUMPRIMENTO DA LEI ESTADUAL 11.150/2020-MT',
          '780': 'INCLUSAO REJEITADA EM CUMPRIMENTO A LEI ESTADUAL 04.793/2020-RO',
          '795': 'INCLUSAO REJEITADA EM CUMPRIMENTO A LEI NRO. 471/2020- JUNDIA - AL',
          '796': 'INCLUSAO REJEITADA EM CUMPRIMENTO A DELIBERACAO ARSESP-1.146',
          '799': 'MOTIVO DE BAIXA DE USO EXCLUSIVO SERASA',
          '900': 'INCLUSAO NAO PERMITIDA - CALAMIDADE PUBLICA',

          // Adicione mais códigos e descrições conforme necessário
          '   ': 'ENTRADA CONFIRMADA', // Adiciona a descrição para o valor em branco
        };

        return descriptions[code.trim()] || 'Descrição Desconhecida'; // Trim para remover espaços extras
      },


      getEmpresaCnpj(code){

        if(code == '006009273'){
          return 'MEMORIAL PARQUE MACEIÓ'
        }else if(code == '012301511'){
          return 'PARQUE DAS FLORES'
        }else if(code == '003178923'){
          return 'CEMITÉRIO PREVIDA'
        }else if(code == '004396985'){
          return 'PREVIPARQ'
        }

      },

      generatePDF(data, headerValue, headerValue_cnpj, nome) {
          const doc = new jsPDF();
          const usuario = JSON.parse(sessionStorage.getItem('user')).nome;

          // Adiciona o cabeçalho
          doc.setFontSize(16);
          doc.text('Relatório de Retorno - SERASA', 14, 20);
          
          // Adiciona o valor da primeira linha
          doc.setFontSize(12);
          doc.text(`Empresa: ${this.getEmpresaCnpj(headerValue_cnpj)}`, 14, 30);

          // Adiciona o valor da primeira linha
          doc.setFontSize(10);
          doc.text(`Sequencial: ${headerValue}`, 14, 35);
          

      
          doc.setFontSize(10);
          doc.text(`Arquivo: ${nome}`, 14, 40);


          // Adiciona a tabela
          doc.autoTable({
            startY: 50,
            head: [['Cliente', 'Descrição']],
            body: data,
          });

           // Adiciona o rodapé
            const pageHeight = doc.internal.pageSize.height;
            const footerY = pageHeight - 20; // Ajuste a posição vertical do rodapé conforme necessário
            doc.setFontSize(10);
            doc.text(`Usuário: ${usuario}`, 14, footerY);
            
            // Adiciona a data e hora de impressão
            const currentDateTime = new Date().toLocaleString(); // Data e hora local
            doc.text(`Data e Hora de Impressão: ${currentDateTime}`, 14, footerY + 5); // Posição abaixo do "Usuário"

            doc.save(headerValue+"_"+headerValue_cnpj+'retorno.pdf');
        },
    },
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  .vuetify__expand-icon {
  display: none !important; /* Oculta os ícones de expandir */
}
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .v-data-table-header-mobile .v-data-table-header-group {
   display: none !important; /* Oculta os botões de expansão de grupo */
  }
  
  </style>
